import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  ContactForm,
  CopyWithFullHeightImage,
  CopyWithImages,
  FindOutMore,
  Layout,
  RescueService,
  SEO,
  SupportMember,
} from '../components';
import { CallToAction } from '../components/call-to-action';

function IndexPage({ data }) {
  return (
    <Layout
      heroImage={data.sanityLandingPage.heroImage.asset.fluid}
      heroAltText={data.sanityLandingPage.heroImage.altText}
    >
      <SEO title="Home" />
      <NewSection data={data.sanityLandingPage.newSection} />
      <OurStory data={data.sanityLandingPage.ourStory} />
      <RescueService />
      <WhatDoWeDo data={data.sanityLandingPage.whatDoWeDo} />
      <FindOutMore />
      <SupportMember />
      <ContactForm />
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function NewSection({ data }) {
  return (
    <CopyWithImages variant="GRAY" reverse images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

NewSection.propTypes = {
  data: PropTypes.object.isRequired,
};
function OurStory({ data }) {
  return (
    <CopyWithImages images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

OurStory.propTypes = {
  data: PropTypes.object.isRequired,
};

function WhatDoWeDo({ data }) {
  return (
    <CopyWithFullHeightImage variant="GREEN" image={data.images[0]}>
      <SanityBlockContent
        blocks={data._rawText}
        className="prose prose-on-dark"
      />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithFullHeightImage>
  );
}

WhatDoWeDo.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    sanityLandingPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      newSection {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      ourStory {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      whatDoWeDo {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

export default IndexPage;
